const SAVE_AVAILABILITY = 'SAVE_AVAILABILITY'

export const availibilityReducer = (state, action) => {
  switch (action.type) {
    case SAVE_AVAILABILITY: {
      state = { ...state, ...action.payload }

      return state
    }

    default:
      return state
  }
}

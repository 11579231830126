import moment from "moment";

export const initialState = {
   profile: {
      id: 1123123123,
      username: "test123"
   },

   /*----------------------*/

   users: {
      // requestStatus: 1,
      // message: 'Success',
      // userInfo: {
      //    _id: '5e2fe0dc05d54d1266222b44',
      //    fName: 'sahil',
      //    lName: 'khan',
      //    email: 'sahilk6540@gmail.com',
      //    mobile: '8871905885',
      //    lastActiveAreaName: 'Indiranagar, Bengaluru',
      //    phoneCountryCode: '+91',
      //    countryCode: '+91',
      //    cityCode: 1,
      //    profilePicUrl:
      //       'https://playov2.gumlet.io/profiles/1594115586669-SAVED-20200707_1523_02904.jpg',
      //    gender: 2,
      //    karma: 469,
      //    bio: '',
      //    emailVerified: false,
      //    officialEmailVerified: false,
      //    verified: true,
      //    superUser: false,
      //    lastPlayed: '2022-01-28T00:00:00.000Z',
      //    publicActivityLimit: 5,
      //    id: '5a72fa23-4369-4081-9035-16a3d5505fda'
      // },
      // token: 'a8d4bed0-8070-11ec-ae4d-4f5406b64f36:5a72fa23-4369-4081-9035-16a3d5505fda',
      // cityInfo: {
      //    _id: '5c19a7ce3a096bf258e08acf',
      //    minOffset: -330,
      //    countryCode: '+91',
      //    radius: 50,
      //    faqUrl: 'https://playo.co/support',
      //    countryName: 'India',
      //    phoneLength: 10,
      //    karmaRatio: 1,
      //    countryId: 'IN',
      //    areaRadius: 4,
      //    sports:
      //       'SP0,SP1,SP2,SP3,SP4,SP5,SP6,SP7,SP8,SP9,SP10,SP11,SP12,SP13,SP14,SP15,SP16,SP17,SP18,SP19,SP20,SP21,SP22,SP24,SP27,SP29,SP30,SP31,SP32,SP33,SP34,SP35,SP36,SP37,SP38',
      //    cityCode: 1,
      //    currencyTxt: 'Rs'
      // }
   },

   /*----------------------*/

   groups: [],

   /*----------------------*/

   cartData: {
      venueId: "",
      sportId: "",
      cartMap: null,
      amountPayable: 0,
      checked: false,
      payableAtVenue: 0,
      cartTotalPrice: 0,
      totalPrice: 0,
      totalGross: 0,
      insurancePrice: 0,
      insurancePrice: 0,
      insureFee: 0,
      insureTxt: "",
      insureInfo: "",
      netAmountPayable: 0,
      creditLimit: 0,
      memberBalance: 0,
      totalFee: 0,
      insureFlag: "",
      discAmount: "",
      full: "",
      convFee: 0,
      taxFee: 0
   },
   /*----------------------*/

   location: {
      showSelected: "hide",
      location: "Click to select location",
      locationLatLon: []
   },
   /*----------------------*/

   filter: {
      allSports: "",
      sportsSelected: [],
      filterCategory: [],
      filterSavedSports: [],
      filterSavedCategory: [],
      checkBoxCount: 0,
      sportsFromNode: "all",
      avgRatingSort: 0,
      appliedFilter: false
   },
   /*----------------------*/

   venuesList: {},
   sponsoredVenues: [],
   allSports: [],
   venueDetails: {},
   memberCredit: { balance: 0 },

   /*----------------------*/

   orderObj: {
      eventId: "notavailable", //hard code only this
      extras: [], //hard code only this
      deviceType: 99, //hard code only this
      karma: 0, //hard code only this
      full: 0 //hard code only this,
   },
   stripeDetails: {
      isStripePay: false,
      clientSecret: "",
      publicKey: ""
   },
   bookableData: {
      sport: {},
      date: moment().format("YYYY-MM-DD"),
      available: false,
      availStatus: ""
   },
   bookableSports: {},
   availability: {},
   loader: {
      loader: false,
      venueError: false,
      fetchLocation: false,
      locationDenied: false,
      bookLoader: false,
      online: true,
      payment: false,
      ratingLoader: false,
      venueListLoader: false
   },
   modal: {
      infoModal: { open: false, text: "", title: "", intent: "" },
      actionModal: { open: false, data: "", title: "" },
      loginModal: { open: false, data: "", title: "" },
      confirmModal: { open: false, data: "", title: "" },
      downloadModal: { open: false },
      locationModal: { open: false },
      homePageLocationModal: { open: false }
   },
   coupon: { couponCode: "", data: "", loader: false },
   insurance: { insureFlag: false, sports: "", insurePrice: 0, insureTxt: "" },
   search: {
      searchData: { open: false, data: [] }
   },
   login: {
      active: 1,
      otp: "",
      otpValid: true,
      userVerified: false,
      loginShow: false,
      loader: false,
      completeProfile: false,
      button: false,
      url: "",
      shouldRender: false
   },
   listTabs: {
      tab: "venue",
      tabsHeader: [
         {
            headerName: "Venues",
            categoryType: "venue",
            headerCount: 0
         }
      ]
   },

   eventCart: {
      deviceType: 99,
      attendees: [],
      isKarma: 0,
      karma: 0,
      paidAmount: 0,
      grossAmount: 0,
      payableVenue: 0,
      slots: null,
      type: 1,
      moreInfo: null
   },
   ticketInfo: [],
   tabSwitchLoader: { loader: "false" },
   otpVerifyData: {
      screen: "",
      profilePicture: "",
      userDetails: "",
      funcAct: ""
   },
   loginErrors: { updateError: "", emailLoginError: "", mobileLoginError: "" }
};

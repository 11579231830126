const OTP_VERIFY_DATA = "OTP_VERIFY_DATA";

const initState = { screen: "update", profilePicture: "", userDetails: "", funcAct: "" };

export const otpVerifyReducer = (state, action) => {
   switch (action.type) {
      case OTP_VERIFY_DATA: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

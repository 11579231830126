const LOGIN_ERROR = "LOGIN_ERROR";

const initState = { updateError: "", emailLoginError: "", mobileLoginError: "" };

export const loginErrorsReducer = (state, action) => {
   switch (action.type) {
      case LOGIN_ERROR: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

const SET_TICKET_INFO = "SET_TICKET_INFO";

export const ticketInfoReducer = (state, action) => {
   switch (action.type) {
      case SET_TICKET_INFO: {
         state = { ...state, ...action.payload.data };

         return state;
      }

      default:
         return state;
   }
};

const ADD_STRIPE_DEATILS = 'ADD_STRIPE_DEATILS'

let initialStripePay = {
  isStripePay: false,
  clientSecret: '', // key fetched from Booking API
  publicKey: '' // key fetched from Booking API
}

export const stripePayReducer = (state, action) => {

  switch (action.type) {
    case ADD_STRIPE_DEATILS: {
    
      state = { ...state, ...action.payload }

      return state
    }

    default:
      return state
  }
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   venueList: {}
};

export const venueListSlice = createSlice({
   name: "venuesList",
   initialState,
   reducers: {
      setVenuesList: (state, action) => {
         state.venueList = action.payload.venueList;
      },
      updateVenues: (state, action) => {
         state.venueList = { ...state.venueList, ...action.payload.venueList };
      }
   }
});

export const { setVenuesList, updateVenues } = venueListSlice.actions;

export default venueListSlice.reducer;

import { userReducer } from "./userReducer";
import { venuesReducer } from "./venuesReducer";
import { venuesDetailsReducer } from "./venueDetailsReducer";
import { allSportsReducer } from "./allSportsReducer";
import { memberCreditReducer } from "./memberCreditReducer";
import { cartReducer } from "./cartReducer";
import { paymentReducer } from "./paymentReducer";
import { selectedDataReducer } from "./selectedDataReducer";
import { availibilityReducer } from "./availibilityReducer";
import { bookableSportReducer } from "./bookableSportReducer";
import { sportsFilterReducer } from "./sportsFilterReducer";
import { locationReducer } from "./locationReducer";
import { loaderReducer } from "./loaderReducer";
import { modalReducer } from "./modalReducer";
import { insuranceReducer } from "./insuranceReducer";
import { couponReducer } from "./couponReducer";
import { searchDataReducer } from "./searchDataReducer";
import { loginReducer } from "./loginReducer";
import { confirmReducer } from "./confirmReducer";
import { errorReducer } from "./errorReducer";
import { countryCodeReducer } from "./countryCodeReducer";
import { helpPluginReducer } from "./helpPluginReducer";
import { listTabsReducer } from "./listTabsController";
import { eventCartReducer } from "./eventCartReducer";
import { ticketInfoReducer } from "./ticketInfoReducer";
import { editAttendeeReducer } from "./editAttendeeReducer";
import { venueLoaderReducer } from "./venueLoaderReducer";
import { serverCallReducer } from "./serverCallReducer";
import { otpVerifyReducer } from "./otpVerifyReducer";
import { loginErrorsReducer } from "./loginErrorReducer";
import { stripePayReducer } from "./stripePayReducer";
import { gameTimeAreaReducer } from "./gameTimeAreaReducer";
import { activityDetails } from "./activityDetails";
import { activityUserData } from "./activityData";
import { modalActivityDetail } from "./modalActivityDetail";
import { joinActivity } from "./joinActivity";
import { payAndJoinData } from "./payAndJoinData";
const mainReducer = (
   {
      listData,
      venueDetails,
      allSports,
      cartData,
      memberCredit,
      orderObj,
      bookableData,
      availability,
      bookableSports,
      filter,
      location,
      loader,
      modal,
      insurance,
      coupon,
      search,
      login,
      users,
      confirm,
      error,
      country,
      helpPlugin,
      listTabs,
      eventCart,
      ticketInfo,
      editAttendee,
      tabSwitchLoader,
      serverCall,
      otpVerifyData,
      loginErrors,
      stripeDetails,
      gameTimeArea,
      activityModals,
      activityDetail,
      activityData,
      joinStatus,
      payAndJoinDetails
   },
   action
) => {
   return {
      users: userReducer(users, action),
      listData: venuesReducer(listData, action),
      venueDetails: venuesDetailsReducer(venueDetails, action),
      allSports: allSportsReducer(allSports, action),
      memberCredit: memberCreditReducer(memberCredit, action),
      cartData: cartReducer(cartData, action),
      orderObj: paymentReducer(orderObj, action),
      bookableData: selectedDataReducer(bookableData, action),
      availability: availibilityReducer(availability, action),
      bookableSports: bookableSportReducer(bookableSports, action),
      filter: sportsFilterReducer(filter, action),
      location: locationReducer(location, action),
      loader: loaderReducer(loader, action),
      modal: modalReducer(modal, action),
      insurance: insuranceReducer(insurance, action),
      coupon: couponReducer(coupon, action),
      search: searchDataReducer(search, action),
      login: loginReducer(login, action),
      users: userReducer(users, action),
      confirm: confirmReducer(confirm, action),
      error: errorReducer(error, action),
      country: countryCodeReducer(country, action),
      helpPlugin: helpPluginReducer(helpPlugin, action),
      listTabs: listTabsReducer(listTabs, action),
      eventCart: eventCartReducer(eventCart, action),
      ticketInfo: ticketInfoReducer(ticketInfo, action),
      editAttendee: editAttendeeReducer(editAttendee, action),
      tabSwitchLoader: venueLoaderReducer(tabSwitchLoader, action),
      serverCall: serverCallReducer(serverCall, action),
      otpVerifyData: otpVerifyReducer(otpVerifyData, action),
      loginErrors: loginErrorsReducer(loginErrors, action),
      stripeDetails: stripePayReducer(stripeDetails, action),
      gameTimeArea: gameTimeAreaReducer(gameTimeArea, action),
      activityDetail: activityDetails(activityDetail, action),
      activityData:activityUserData(activityData,action),
      activityModals:modalActivityDetail(activityModals,action),
      joinStatus:joinActivity(joinStatus,action),
      payAndJoinDetails:payAndJoinData(payAndJoinDetails,action)
   };
};

export default mainReducer;

const CART_DATA = 'CART_DATA';
const RESET_CART = 'RESET_CART';

const cartInitialData = {
   venueId: '',
   sportId: '',
   cartMap: null,
   amountPayable: 0,
   checked: false,
   payableAtVenue: 0,
   cartTotalPrice: 0,
   totalPrice: 0,
   totalGross: 0,
   insurancePrice: 0,
   insurancePrice: 0,
   insureFee: 0,
   insureTxt: '',
   insureInfo: '',
   netAmountPayable: 0,
   creditLimit: 0,
   memberBalance: 0,
   totalFee: 0,
   insureFlag: '',
   discAmount: '',
   full: '',
   convFee: 0,
   taxFee: 0
};

export const cartReducer = (state, action) => {
   switch (action.type) {
      case CART_DATA: {
         state = { ...state, ...action.payload };

         return state;
      }
      case RESET_CART: {
         state = { ...cartInitialData };

         return state;
      }

      default:
         return state;
   }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   userDetails: {}
};

export const userDetailsSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      setUserDetails: (state, action) => {
         state.userDetails = action.payload;
      }
   }
});

export const { setUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;

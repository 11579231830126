const FETCH_SPORTS = 'FETCH_SPORTS';
const SELECTED_SPORTS = 'SELECTED_SPORTS';
const FILTER_CATEGORY = 'FILTER_CATEGORY';
const FILTER_SAVED_SPORTS = 'FILTER_SAVED_SPORTS';
const FILTER_SAVED_CATEGORY = 'FILTER_SAVED_CATEGORY';
const CHECKBOX_COUNT = 'CHECKBOX_COUNT';
const SPORT_FROM_NODE = 'SPORT_FROM_NODE';
const SET_RATING = 'SET_RATING';
const RESET_FILTER = 'RESET_FILTER';
const APPLIED_FILTER = 'APPLIED_FILTER';

export const sportsFilterReducer = (state, action) => {
   switch (action.type) {
      case FETCH_SPORTS: {
         return { ...state, allSports: action.payload };
      }
      case SELECTED_SPORTS: {
         return { ...state, sportsSelected: action.payload };
      }

      case FILTER_CATEGORY: {
         return { ...state, filterCategory: action.payload };
      }

      case FILTER_SAVED_SPORTS: {
         return { ...state, filterSavedSports: action.payload };
      }

      case FILTER_SAVED_CATEGORY: {
         return { ...state, filterSavedCategory: action.payload };
      }

      case CHECKBOX_COUNT: {
         return { ...state, checkBoxCount: action.payload };
      }
      case SPORT_FROM_NODE: {
         return { ...state, sportsFromNode: action.payload };
      }

      case SET_RATING: {
         return { ...state, avgRatingSort: action.payload };
      }
      case APPLIED_FILTER: {
         return { ...state, appliedFilter: action.payload };
      }
      case RESET_FILTER: {
         return {
            allSports: '',
            sportsSelected: [],
            filterCategory: [],
            filterSavedSports: [],
            filterSavedCategory: [],
            checkBoxCount: 0,
            sportsFromNode: 'all',
            avgRatingSort: 0,
            appliedFilter:false
         };
      }

      default:
         return state;
   }
};

const TAB_SWITCH_LOADER = "TAB_SWITCH_LOADER";

export const venueLoaderReducer = (state, action) => {
   switch (action.type) {
      case TAB_SWITCH_LOADER: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

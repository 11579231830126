const VERIFY_BOOKING_ERROR = 'VERIFY_BOOKING_ERROR';

const initState = { verifyError: false, verifyErrorMessage: '' };
export const errorReducer = (state, action) => {
   switch (action.type) {
      case VERIFY_BOOKING_ERROR: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

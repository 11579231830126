const JOIN_ACTIVITY_DETAILS = "JOIN_ACTIVITY_DETAILS";
const JOIN_ACTIVITY_DETAILS_FALSE = "JOIN_ACTIVITY_DETAILS_FALSE";
const joinActivityDetailsStaus = {
    status:false,
    handleOnProceed:""
};
export const joinActivity = (state, action) => {
   switch (action.type) {
      case JOIN_ACTIVITY_DETAILS: {
         state = { ...state, ...action.payload };
         return state;
      }
      case JOIN_ACTIVITY_DETAILS_FALSE: {
         
         return joinActivityDetailsStaus;
      }
      default:
         return state;
   }
};

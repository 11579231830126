const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
const RESET_SEARCH_DATA = 'RESET_SEARCH_DATA';

const initialState = {
   searchData: { open: false, data: '', query: { query: '' } }
};

export const searchDataReducer = (state, action) => {
   switch (action.type) {
      case SET_SEARCH_DATA: {
         state = { ...state, searchData: { ...action.payload } };

         return state;
      }
      case RESET_SEARCH_DATA: {
         state = { ...initialState };

         return state;
      }

      default:
         return state;
   }
};

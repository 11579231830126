const SERVER_CALL = "SERVER_CALL";

const initialState = {
   serverCall: false
};

export const serverCallReducer = (state = initialState, action) => {
   // console.log({ action });
   switch (action.type) {
      case SERVER_CALL: {
         state = { ...action.payload };

         return state;
      }
      default:
         return state;
   }
};

const MEMBER_CREDIT = 'MEMBER_CREDIT'

export const memberCreditReducer = (state, action) => {
  switch (action.type) {
    case MEMBER_CREDIT: {
      state = { ...state, ...action.payload.data }

      return state
    }

    default:
      return state
  }
}

const SET_VENUE_LIST = "SET_VENUE_LIST";
const RESET_VENUES_LIST = "RESET_VENUES_LIST";

export const venuesReducer = (state, action) => {
   switch (action.type) {
      case SET_VENUE_LIST: {
         state = { ...state, ...action.payload };

         return state;
      }
      case RESET_VENUES_LIST: {
         return [];
      }

      default:
         return state;
   }
};

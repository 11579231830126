const SET_ACTIVITY_DATA = "SET_ACTIVITY_DATA";
const CLEAR_ACTIVITY_DATA = "CLEAR_ACTIVITY_DATA";
const activityInitalUserData = {
   activityInfo: {},
   hostId: "",
   userInfo: [],
   hostInfo: {},
   cohostId: "",
   cohostInfo: {},
   activityInfo: {},
   playPals: [],
   timing: {},
   type: 0,
   price: "",
   reuquiredPlayers: -1,
   tags: []
};
export const activityUserData = (state, action) => {
   switch (action.type) {
      case SET_ACTIVITY_DATA: {
         state = { ...state, ...action.payload };
         return state;
      }
      case CLEAR_ACTIVITY_DATA: {
         state = { ...state,...activityInitalUserData };

         return state;
      }

      default:
         return state;
   }
};

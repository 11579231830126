import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   location: {
      showSelected: "hide",
      location: "Click to select location",
      locationLatLon: []
   }
};

export const locationSlice = createSlice({
   name: "locationState",
   initialState,
   reducers: {
      setLocation: (state, action) => {
         state.location = { ...action.payload.location };
      },
      updateLocation: (state, action) => {
        
         state.location = { ...state.location, ...action.payload.location };
      },
      resetLocation: (state, action) => {
         state.location = { ...initialState };
      }
   }
});

export const { setLocation, updateLocation, resetLocation } = locationSlice.actions;

export default locationSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   filter: {
      allSports: "",
      sportsSelected: [],
      filterCategory: [],
      filterSavedSports: [],
      filterSavedCategory: [],
      checkBoxCount: 0,
      sportsFromNode: "all",
      avgRatingSort: 0,
      appliedFilter: false
   }
};

export const filterSlice = createSlice({
   name: "filterState",
   initialState,
   reducers: {
      setFilter: (state, action) => {
         state.filter = { ...action.payload.filter };
      },
      updateFilter: (state, action) => {
        
         state.filter = { ...state.filter, ...action.payload.filter };
      },
      resetFilter: (state, action) => {
         state.filter = { ...initialState };
      }
   }
});

export const { setFilter, updateFilter, resetFilter } = filterSlice.actions;

export default filterSlice.reducer;

const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';

const initialState = {
   name: 'India',
   callingCodes: ['91'],
   alpha3Code: 'IND',
   alpha2Code:"IN"
};

export const countryCodeReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_COUNTRY_DATA: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

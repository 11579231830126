import React, { createContext, useContext, useReducer } from "react";

export const StateContext = createContext();

export const StateProvider = ({ children, reducers, initialState }) => (
   <StateContext.Provider value={useReducer(reducers, initialState)}>
      {children}
   </StateContext.Provider>
);

export const UseStateValue = () => useContext(StateContext);

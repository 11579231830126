const CURRENT_LOCATION = 'CURRENT_LOCATION';
const SHOW_SELECTED = 'SHOW_SELECTED';
const LAT_LONG = 'LAT_LONG';

const locationState = {
   showSelected: 'hide',
   location: 'Click to select location',
   locationLatLon: []
};

export const locationReducer = (state, action) => {
   switch (action.type) {
      case CURRENT_LOCATION: {
         state = { ...state, location: action.payload };

         return state;
      }
      case SHOW_SELECTED: {
         state = { ...state, showSelected: action.payload };

         return state;
      }
      case LAT_LONG: {
         state = { ...state, locationLatLon: action.payload };
         return state;
      }
      default:
         return state;
   }
};

const GET_ACTIVITY_DETAILS = "GET_ACTIVITY_DETAILS";
const SET_ACTIVITY_DETAILS = "SET_ACTIVITY_DETAILS";
const CLEAR_ACTIVITY_DETAILS = "CLEAR_ACTIVITY_DETAILS";
const initialLoad={}
export const activityDetails = (state, action) => {
   switch (action.type) {
      case GET_ACTIVITY_DETAILS: {
         state = { ...state, ...action.payload };

         return state;
      }
      case SET_ACTIVITY_DETAILS: {
         state = { ...state, ...action.payload };

         return state;
      }
      case CLEAR_ACTIVITY_DETAILS: {
         state = { ...initialLoad};

         return state;
      }

      default:
         return state;
   }
};

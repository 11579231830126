const SET_EVENT_CART = "SET_EVENT_CART";
const RESET_EVENT_CART = "RESET_EVENT_CART";

let eventCartInitialData = {
   deviceType: 99,
   isKarma: 0,
   karma: 0,
   paidAmount: 0,
   grossAmount: 0,
   payableVenue: 0,
   slots: null,
   type: 1,
   moreInfo: null,
   attendees: []
};

export const eventCartReducer = (state, action) => {
   switch (action.type) {
      case SET_EVENT_CART: {
         state = { ...state, ...action.payload };

         return state;
      }
      case RESET_EVENT_CART: {
         state = { ...eventCartInitialData };

         return state;
      }

      default:
         return state;
   }
};

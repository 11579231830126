const CONFIRM_INFO = 'CONFIRM_INFO';

export const confirmReducer = (state = '', action) => {
   switch (action.type) {
      case CONFIRM_INFO: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

const INFO_MODAL = "INFO_MODAL";
const ACTION_MODAL = "ACTION_MODAL";
const CONFIRM_MODAL = "CONFIRM_MODAL";
const DOWNLOAD_MODAL = "DOWNLOAD_MODAL";
const LOCATION_MODAL = "LOCATION_MODAL";
const HOMEPAGE_LOCATION_MODAL = "HOMEPAGE_LOCATION_MODAL";
const LOGIN_MODAL = "LOGIN_MODAL";

const initialState = {
   infoModal: { open: false, data: "", title: "", intent: "" },
   actionModal: { open: false, data: "", title: "" },
   loginModal: { open: false, data: "", title: "" },
   downloadModal: { open: false },
   locationModal: { open: false },
   homePageLocationModal: { open: false }
};

export const modalReducer = (state, action) => {
   switch (action.type) {
      case INFO_MODAL: {
         state = { ...state, infoModal: { ...action.payload } };

         return state;
      }
      case ACTION_MODAL: {
         state = { ...state, actionModal: action.payload };

         return state;
      }
      case LOGIN_MODAL: {
         state = { ...state, loginModal: action.payload };

         return state;
      }
      case CONFIRM_MODAL: {
         state = { ...state, confirmModal: action.payload };

         return state;
      }

      case DOWNLOAD_MODAL: {
         state = { ...state, downloadModal: action.payload };

         return state;
      }

      case LOCATION_MODAL: {
         state = { ...state, locationModal: action.payload };

         return state;
      }
      case HOMEPAGE_LOCATION_MODAL: {
         state = { ...state, homePageLocationModal: action.payload };

         return state;
      }

      default:
         return state;
   }
};

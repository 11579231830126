const USER_DETAIL = 'USER_DETAIL';
const USER_LOGOUT = 'USER_LOGOUT';

const initState = {
   fName: '',
   lName: '',
   email: '',
   mobile: '',
   fNameValid: true,
   lNameValid: true,
   emailValid: true,
   mobileValid: true,
   userDetail: null,
   existingUser: false
};

export const userReducer = (state, action) => {
   switch (action.type) {
      case USER_DETAIL: {
         state = { ...action.payload };

         return state;
      }

      case USER_LOGOUT:
         return initState;
      default:
         return state;
   }
};


const SWITCH_LOADER = "SWITCH_LOADER";
const PAYMENT_LOADER = "PAYMENT_LOADER";
const FETCH_LOCATION = "FETCH_LOCATION";
const LOCATION_DENIED = "LOCATION_DENIED";
const BOOK_LOADER = "BOOK_LOADER";
const ONLINE = "ONLINE";
const VENUE_ERROR = "VENUE_ERROR";
const RATING_LOADER = "RATING_LOADER";
const LIST_LOADER = "LIST_LOADER";

const initialState = {
   loader: false,
   venueError: false,
   fetchLocation: false,
   locationDenied: false,
   bookLoader: false,
   online: true,
   payment: false,
   ratingLoader: false,
   venueListLoader: false
};

export const loaderReducer = (state, action) => {
   switch (action.type) {
      case SWITCH_LOADER: {
         state = { ...state, loader: action.payload };

         return state;
      }
      case PAYMENT_LOADER: {
         state = { ...state, payment: action.payload };

         return state;
      }

      case FETCH_LOCATION: {
         state = { ...state, fetchLocation: action.payload };

         return state;
      }
      case LOCATION_DENIED: {
         state = { ...state, locationDenied: action.payload };
      }
      case BOOK_LOADER: {
         state = { ...state, bookLoader: action.payload };
      }

      case ONLINE: {
         state = { ...state, online: action.payload };
      }
      case VENUE_ERROR: {
         state = { ...state, venueError: action.payload };
      }
      case RATING_LOADER: {
         state = { ...state, ratingLoader: action.payload };
      }

      case LIST_LOADER: {
         state = { ...state, venueListLoader: action.payload };
      }
      default:
         return state;
   }
};

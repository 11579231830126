const SELECT_BOOKABLE_DATA = 'SELECT_BOOKABLE_DATA'
import moment from 'moment'

let initialState = {
  sport: {},
  date: moment().format('YYYY-MM-DD'),
  available: false,
  availStatus: ''
}
export const selectedDataReducer = (state, action) => {
  switch (action.type) {
    case SELECT_BOOKABLE_DATA: {
      state = { ...state, ...action.payload }

      return state
    }

    default:
      return state
  }
}

const SET_ALL_SPORTS = 'SET_ALL_SPORTS'

export const allSportsReducer = (state, action) => {
 
  switch (action.type) {
    case SET_ALL_SPORTS: {
  
      state = { ...state, ...action.payload.data }

      return state
    }

    default:
      return state
  }
}

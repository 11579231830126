const LOGIN_SIGNUP = 'LOGIN_SIGNUP';
const USER_LOGOUT = 'USER_LOGOUT';
// active = 1 / login screen
// active = 2 / signup screen
// active = 3 / otp screen

const initState = {
   active: 1,
   otp: '',
   otpValid: true,
   userVerified: false,
   loginShow: false,
   loader: false,
   completeProfile: false,
   button: false,
   url: '',
   shouldRender: false
};

export const loginReducer = (state, action) => {
   switch (action.type) {
      case LOGIN_SIGNUP: {
         state = { ...state, ...action.payload };

         return state;
      }
      case USER_LOGOUT:
         return initState;

      default:
         return state;
   }
};

const MODAL_ACTIVITY_DETAILS = "MODAL_ACTIVITY_DETAILS";
const MODAL_ACTIVITY_DETAILS_SELECT_SKILLS = "MODAL_ACTIVITY_DETAILS_SELECT_SKILLS";
const MODAL_ACTIVITY_DETAILS_SEND_QUERY = "MODAL_ACTIVITY_DETAILS_SEND_QUERY";
const MODAL_ACTIVITY_DETAILS_PAY_AND_JOIN = "MODAL_ACTIVITY_DETAILS_PAY_AND_JOIN";
const MODAL_ACTIVITY_DETAILS_USER_UPDATE = "MODAL_ACTIVITY_DETAILS_USER_UPDATE";
const initialState = {
   selectSkills: false,
   sendQuery: false,
   payAndJoin: false,
   userUpdate: false
};
export const modalActivityDetail = (state, action) => {
   switch (action.type) {
      case MODAL_ACTIVITY_DETAILS_SELECT_SKILLS: {
         state = { ...state, ...initialState, ...action.payload };
         return state;
      }
      case MODAL_ACTIVITY_DETAILS_SEND_QUERY: {
         state = { ...state, ...initialState, ...action.payload };
         return state;
      }
      case MODAL_ACTIVITY_DETAILS_PAY_AND_JOIN: {
         state = { ...state, ...initialState, ...action.payload };
         return state;
      }
      case MODAL_ACTIVITY_DETAILS_USER_UPDATE: {
         state = { ...state, ...initialState, ...action.payload };
         return state;
      }
      case MODAL_ACTIVITY_DETAILS: {
         state = { ...state, ...initialState };
         return state;
      }
      default:
         return state;
   }
};

const SAVE_BOOKABLE_SPORTS = 'SAVE_BOOKABLE_SPORTS';
const SAVE_BOOKABLE_ERROR = 'SAVE_BOOKABLE_ERROR';
export const bookableSportReducer = (state, action) => {
   switch (action.type) {
      case SAVE_BOOKABLE_SPORTS: {
         state = { ...action.payload.data }

         return state;
      }
      case SAVE_BOOKABLE_ERROR: {
         state = { ...action.payload.data };

         return state;
      }

      default:
         return state;
   }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   venueDetail: {}
};

export const venueDetailSlice = createSlice({
   name: "venueDetail",
   initialState,
   reducers: {
      setVenueDetails: (state, action) => {
         state.venueDetail = action.payload;
      }
   }
});

export const { setVenueDetails } = venueDetailSlice.actions;

export default venueDetailSlice.reducer;

const EDIT_ATTENDEE = "EDIT_ATTENDEE";

export const editAttendeeReducer = (state, action) => {
   switch (action.type) {
      case EDIT_ATTENDEE: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   allSport: {}
};

export const allSportsSlice = createSlice({
   name: "allSports",
   initialState,
   reducers: {
      setAllSports: (state, action) => {
         state.allSport = action.payload;
      }
   }
});

export const { setAllSports } = allSportsSlice.actions;

export default allSportsSlice.reducer;

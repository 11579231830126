const MAKE_ORDER = 'MAKE_ORDER'
const ADD_STRIPE_DEATILS = 'ADD_STRIPE_DEATILS'

let initialOrderObj = {
  eventId: 'notavailable', //hard code only this
  extras: [], //hard code only this
  deviceType: 99, //hard code only this
  karma: 0, //hard code only this
  full: 0 //hard code only this
}

export const paymentReducer = (state, action) => {

  switch (action.type) {
    case MAKE_ORDER: {
    
      state = { ...state, ...action.payload }

      return state
    }

    default:
      return state
  }
}

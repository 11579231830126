const HELP_PLUGIN = 'HELP_PLUGIN';

export const helpPluginReducer = (state = '', action) => {
   switch (action.type) {
      case HELP_PLUGIN: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

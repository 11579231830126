const INSURE_FLAG = 'INSURE_FLAG';
const RESET_INSURANCE = 'RESET_INSURANCE';

const initState = { insureFlag: false, sports: '', insurePrice: 0, insureTxt: '' };

export const insuranceReducer = (state, action) => {
 
   switch (action.type) {
      case INSURE_FLAG: {
         state = { ...state, ...action.payload.data };

         return state;
      }
      case RESET_INSURANCE: {
         state = initState;

         return state;
      }

      default:
         return state;
   }
};

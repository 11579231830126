const SET_GAMETIME_AREA = 'SET_GAMETIME_AREA';
const SELECT_GAMETIME_AREA = 'SELECT_GAMETIME_AREA';

const initialState = {
  areas: [],
  selectedArea: null,
  isSelected: false
};

export const gameTimeAreaReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case SET_GAMETIME_AREA: {
  
      state = { ...state, areas: { ...action.payload.data }, selectedArea: action.payload.selectedArea, isSelected: false }

      return state;
    }

    case SELECT_GAMETIME_AREA: {

      state = { ...state, selectedArea: action.payload, isSelected: true };

      return state;
    }

    default:
      return state
  }
}

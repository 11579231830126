const SET_VENUE_INFO = 'SET_VENUE_INFO'

export const venuesDetailsReducer = (state, action) => {
 
  switch (action.type) {
    case SET_VENUE_INFO: {
    
      state = { ...state, ...action.payload.data }

      return state
    }

    default:
      return state
  }
}

const CHANGE_TAB = "CHANGE_TAB";
const SET_TAB_LIST = "SET_TAB_LIST";

export const listTabsReducer = (state, action) => {
   switch (action.type) {
      case CHANGE_TAB: {
         state = { ...state, tab: action.payload };

         return state;
      }
      case SET_TAB_LIST: {
         state = { ...state, ...action.payload };

         return state;
      }

      default:
         return state;
   }
};

const PAY_AND_JOIN_DATA = "PAY_AND_JOIN_DATA";
const CLEAR_PAY_AND_JOIN_DATA = "CLEAR_PAY_AND_JOIN_DATA";
const payAndJoinInitial = {};
export const payAndJoinData = (state, action) => {
   switch (action.type) {
      case PAY_AND_JOIN_DATA: {
         state = { ...state, ...action.payload };
         return state;
      }
      case CLEAR_PAY_AND_JOIN_DATA: {
         state = { ...activityInitalUserData };

         return state;
      }

      default:
         return state;
   }
};

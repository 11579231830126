import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import venuesList from "./venuesListSlice";
import allSports from "./allSportsSlice";
import filter from "./filterSlice";
import user from "./userDetailsSlice";
import location from "./locationSlice";
import venueDetail from "./venueDetailSlice";

const combinedReducer = combineReducers({
   venuesList,
   allSports,
   filter,
   user,
   location,
   venueDetail
});

const masterReducer = (state, action) => {
   if (action.type === HYDRATE) {
      const nextState = {
         ...state, // use previous state
         ...action.payload
      };
      return nextState;
   } else {
      return combinedReducer(state, action);
   }
};

export const makeStore = () =>
   configureStore({
      reducer: masterReducer,
      middleware: getDefaultMiddleware =>
         getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
         })
   });

export const wrapper = createWrapper(makeStore, { debug: true });

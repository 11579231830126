const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE';
const REMOVE_COUPON_CODE = 'REMOVE_COUPON_CODE';
const initState = { couponCode: '', data: '', loader: false, couponApplied: false };
export const couponReducer = (state, action) => {
   switch (action.type) {
      case APPLY_COUPON_CODE: {
         state = { ...state, ...action.payload.data };

         return state;
      }

      case REMOVE_COUPON_CODE: {
         state = initState;

         return state;
      }
      default:
         return state;
   }
};
